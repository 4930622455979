import { IamportPayMethod } from '~/core';

export type PaymentStatus = 'ready' | 'paid' | 'failed' | 'cancelled';

export type PaymentMethod = Extract<IamportPayMethod, 'card' | 'trans' | 'vbank'>;

export const paymentMethodNameMap: Readonly<Record<PaymentMethod, string>> = {
  card: '카드결제',
  trans: '실시간계좌이체',
  vbank: '무통장입금',
};

export interface Payment {
  id: number;
  orderId: string;
  status?: PaymentStatus;
  payMethod: PaymentMethod;
  name: string;
  amount: number;
  buyerName?: string;
  buyerEmail?: string;
  paid: boolean | null;
  cancelReason?: string;
  cancelAt?: string;
  refundHolder?: string;
  refundBankCode?: string;
  refundAccount?: string;
  refundAmount?: number;
  refundAt?: string;
  createdAt: string;
  updatedAt: string;
  couponsCount?: number;
}

export interface VBankInfo {
  paid: boolean;
  amount: number;
  name: string;
  num: string;
  holder?: string;
  /** UNIX Timestamp */
  dueDate?: number;
}
