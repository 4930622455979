import { difference, uniq } from '@florencecard-lib/functional';
import {
  LetterTheme,
  LetterThemeMetadata,
  letterThemeMetadataMap,
  letterThemes as letterThemeList,
} from '@florencecard-shared/letter';
import { objectToOptions, Option } from '~/models/option';
import { Payment } from '~/models/payment';
import { isNotEmpty, Nullable } from '~/utils';

export const LETTER_ORIGINAL_AMOUNT = 15_000;

export interface LetterThemeData extends LetterThemeMetadata {
  id: LetterTheme;
}

const priorityThemes: LetterTheme[] = [
  'whiteflower1',
  'yellowflower1',
  'theme2',
  'theme3',
  'theme4',
  'magazine1',
  'purple1',
  'wine1',
  'theme1',
  'flat1',
];
const sortedThemes = uniq([...priorityThemes, ...difference(letterThemeList, priorityThemes)]);

export const isLetterTheme = (value: unknown): value is LetterTheme =>
  typeof value === 'string' && letterThemeList.includes(value as LetterTheme);

export const letterThemes: LetterThemeData[] = sortedThemes.map((theme) => ({
  id: theme,
  ...letterThemeMetadataMap[theme],
}));

export const letterThemeStoreDetails: Partial<
  Record<
    LetterTheme,
    {
      imgSrc?: string;
      imgSrcSet?: string;
      title: string;
      description: string;
    }
  >
> = {
  whiteflower1: {
    imgSrc: 'https://assets.florencecard.me/images/img-flower-white-3@2x.png',
    imgSrcSet:
      'https://assets.florencecard.me/images/img-flower-white-3.png 1x, https://assets.florencecard.me/images/img-flower-white-3@2x.png 2x, https://assets.florencecard.me/images/img-flower-white-3@3x.png 3x',
    title: '꽃잎이 날리는 모바일 청첩장',
    description: '꽃잎이 날리는 우아한 화이트플라워 테마를 바로 확인해 보세요.',
  },
  yellowflower1: {
    imgSrc: 'https://assets.florencecard.me/images/img-flower-yellow-2@2x.png',
    imgSrcSet:
      'https://assets.florencecard.me/images/img-flower-yellow-2.png 1x, https://assets.florencecard.me/images/img-flower-yellow-2@2x.png 2x, https://assets.florencecard.me/images/img-flower-yellow-2@3x.png 3x',
    title: '꽃잎이 날리는 모바일 청첩장',
    description: '꽃잎이 날리는 우아한 옐로우플라워 테마를 바로 확인해 보세요.',
  },
  frame1: {
    imgSrc: 'https://assets.florencecard.me/images/img-frame1-flower1@2x.png',
    imgSrcSet:
      'https://assets.florencecard.me/images/img-frame1-flower1.png 1x, https://assets.florencecard.me/images/img-frame1-flower1@2x.png 2x, https://assets.florencecard.me/images/img-frame1-flower1@3x.png 3x',
    title: '꽃잎이 날리는 모바일 청첩장',
    description: '꽃잎이 날리는 우아한 프레임 테마를 바로 확인해 보세요.',
  },
};

export type LetterInfoType =
  | 'email'
  | 'groom'
  | 'priest'
  | 'place'
  | 'note'
  | 'photo'
  | 'accounts'
  | 'etc'
  | 'theme'
  | 'pay';

export interface LetterGroomInfo {
  name: string;
  showPhone: boolean;
  phone1: string;
  phone2: string;
  phone3: string;
  order: string;
  fatherName: string;
  motherName: string;
  requireParentPhone: boolean;
  fatherPhone1?: string;
  fatherPhone2?: string;
  fatherPhone3?: string;
  motherPhone1?: string;
  motherPhone2?: string;
  motherPhone3?: string;
}

export interface LetterPriestInfo {
  name: string;
  showPhone: boolean;
  phone1: string;
  phone2: string;
  phone3: string;
  order: string;
  fatherName: string;
  motherName: string;
  requireParentPhone: boolean;
  fatherPhone1?: string;
  fatherPhone2?: string;
  fatherPhone3?: string;
  motherPhone1?: string;
  motherPhone2?: string;
  motherPhone3?: string;
}

export interface LetterPlaceInfo {
  date: string;
  time: string;
  zipCode: string;
  address: string;
  jibunAddress?: string;
  addressDetail: string;
  showPlace?: boolean;
  x?: string;
  y?: string;
  phone1: string;
  phone2: string;
  phone3: string;
}

export interface LetterWayToComeInfo {
  subway?: string;
  bus?: string;
  car?: string;
  etc?: string;
  mapImage?: string | null;
}

export interface LetterNoteInfo {
  note1: string;
  note2: string;
}

export type LetterPhotoGalleryType = 'swipe' | 'photoAlbum';

export const letterPhotoGalleryTypeOptions: Option<LetterPhotoGalleryType>[] = [
  {
    name: '스와이프',
    value: 'swipe',
  },
  {
    name: '포토 앨범',
    value: 'photoAlbum',
  },
];

export interface LetterPhotoInfo {
  mainImage: string | null;
  secondImage: string | null;
  lastImage: string | null;
  gallery: string[];
  galleryType: LetterPhotoGalleryType;
}

export interface LetterAccount {
  name: string;
  bankName: string;
  no: string;
  kakaopayQRCodeUrl?: string;
}

export interface LetterAccountsInfo {
  title: string | null;
  description: string | null;
  groom: LetterAccount | null;
  priest: LetterAccount | null;
  additionalGroom: LetterAccount | null;
  additionalPriest: LetterAccount | null;
}

export interface LetterThemeInfo {
  theme: LetterTheme;
}

export enum LetterOGTitleVariant {
  '김신랑, 박신부의 결혼을 축하해주세요.' = 'legacy',
  '김신랑♥박신부 결혼합니다.' = 'heart',
}

export const letterOGTitleVariants = Object.values(LetterOGTitleVariant);

export function isLetterOGTitleVariant(value: unknown): value is LetterOGTitleVariant {
  return typeof value === 'string' && letterOGTitleVariants.some((x) => x === value);
}

export interface LetterOGInfo {
  title: LetterOGTitleVariant;
}

export interface LetterAttendsInfo {
  enabled: boolean;
  title: string;
  description: string;
}

export interface LetterGuestBooksInfo {
  enabled: boolean;
}

export const letterGroomInfoOrderOptions = [
  { name: '장남', value: '장남' },
  { name: '차남', value: '차남' },
  { name: '삼남', value: '삼남' },
  { name: '사남', value: '사남' },
  { name: '아들', value: '아들' },
  { name: '표기안함', value: '표기안함' },
];

export const letterPriestInfoOrderOptions = [
  { name: '장녀', value: '장녀' },
  { name: '차녀', value: '차녀' },
  { name: '삼녀', value: '삼녀' },
  { name: '사녀', value: '사녀' },
  { name: '딸', value: '딸' },
  { name: '표기안함', value: '표기안함' },
];

export const letterNote1Values = [
  '각자 서로 다른 길을 걸어온 저희가\n' +
    '두 사람 사랑으로 하나되는 뜻 깊은 날을\n' +
    '맞이하게 되었습니다.\n' +
    '부디 참석해 주시어 아낌없는 격려로\n' +
    '축복해 주시기 바랍니다.',
  '서로가 마주보며 다져온 사랑을\n' +
    '이제 함께 한 곳을 바라보며\n' +
    '걸어갈 수 있는 큰 사랑으로 키우고자 합니다.\n' +
    '저희 두 사람이 사랑의 이름으로 \n' +
    '지켜나갈 수 있게\n' +
    '앞날을 축복해 주시면 감사하겠습니다.',
  '너무나 닮은 두 사람이 있습니다.\n' +
    '작은 것에 감사할 줄 아는 마음이 닮았으며,\n' +
    '상대방을 배려하는 바다처럼\n' +
    '넓은 마음이 닮았습니다.\n' +
    '이 두사람이 하나가 되어 \n' +
    '새로운 시작을 하려 합니다.\n' +
    '함께 하셔서 축복해 주시면 큰 기쁨이겠습니다.',
  '오래전부터 기다려 왔던 사람을\n' +
    '드디어 만났습니다.\n' +
    '먼 길 힘드시겠지만 꼭 오셔서 여러분들이\n' +
    '걸어오셨던 인생의 지혜와 용기를 \n' +
    '저희에게 나누어 주세요.\n' +
    '여러분들께서 가르쳐 주신 대로 \n' +
    '바르게 제대로 걸어가겠습니다.',
  '두 사람이 하나가 될 새 인생을 시작합니다.\n' +
    '즐거움은 나누고 어려움을 이겨내며\n' +
    '함께 나아가는 삶을 꾸리겠습니다.',
  '오랫동안 기다린 사랑\n' +
    '눈에 밟혀서 이야기가 통해서 시작된 사랑\n' +
    '잊혀지지 않는 하나의 꽃이 되고 싶습니다.',
  '새로운 출발이라고 생각하는 결혼식\n' +
    '기다리고 기다렸던 이 날이 오기까지\n' +
    '많은 사람들의 관심이 저희를\n' +
    '여기까지 올 수 있게 만들었던 것 같습니다.\n' +
    '앞으로도 행복한 가정을 이룰 수 있도록 \n' +
    '축복을 빌어주세요.',
  '저희 두 사람이 새 출발의 첫 걸음을 내딛습니다.\n' +
    '저희 약속 위에 따뜻한 격려로 축복해 주셔서\n' +
    '힘찬 출발의 디딤이 되어 주십시오.',
  '오래 전 작은 인연이 저희를 연인으로 만들었고\n' +
    '지금 그 인연으로 저희는 하나가 됩니다.\n' +
    '아직은 많이 부족하지만 늘 그 인연을 생각하며\n' +
    '서로 아껴주고 사랑하며 살겠습니다.\n' +
    '오셔서 지켜봐주시고 축하해 주십시오.',
  '저희 두 사람의 소중한 만남이 결실을 맺어\n' +
    '혼인의 예를 갖추려 합니다.\n' +
    '늘 밝고 행복하게 살도록 노력하겠습니다.\n' +
    '귀한 걸음 하시어 축복해주시면\n' +
    '큰 기쁨이겠습니다.',
];

export const letterNote2Values = [
  '서로가 서로에게\n' + '동행이 되어주는 날',
  '장담하건대, 세상이 다 겨울이어도\n' +
    '우리사랑은 늘 봄처럼 따뜻하고\n' +
    '간혹, 여름처럼 뜨거울 겁니다\n' +
    '그대 사랑합니다\n' +
    '이수동, <사랑가>\n',
  '꽃같은 그대\n' +
    '나무같은 나를 믿고 길을 나서자\n' +
    '그대는 꽃이라서 십년이면 \n' +
    '열번은 변하겠지만\n' +
    '나는 나무같아서 그 십년,\n' +
    '내속에 둥근 나이테로만 남기고 말겠다\n' +
    '이수동, <동행>',
  '사랑은 소유가 아니라\n' +
    '동행임을 아는 두 사람은 \n' +
    '잡은 손을 놓지 않되\n' +
    '함부로 잡아끌지 않을 것이며\n' +
    '서로의 두 눈을 고요히 바라보아\n' +
    '말하지 않아도 같은 쪽으로 걸어가리라\n' +
    '박미라, <아름다운 날에 부치다>\n',
  '밤하늘 무수한 별들 가운데\n' +
    '하나를 봅니다\n' +
    '지구의 많은 사람들 가운데\n' +
    '내가 지금 그 별을 봅니다\n' +
    '사람과 사람의 만남도\n' +
    '이처럼 수천만 분의 일의\n' +
    '우연과 같은 필연으로 인연을 맺습니다\n' +
    '혜민스님, <멈춰야 비로소 보이는 것들>',
  '어제 거기가 아니고\n' +
    '내일 저기도 아니고\n' +
    '다만 오늘 여기\n' +
    '그리고 당신\n' +
    '나태주, <행복>',
  '서로의 이름을 부르는 것만으로도\n' +
    '사랑의 깊이를 확인할 수 있는 두 사람이\n' +
    '꽃과 나무처럼 걸어와서\n' +
    '서로의 모든것이 되기 위해\n' +
    '오랜 기다림 끝에 혼례식을 치르는 날\n' +
    '세상은 더욱 아름다워라\n' +
    '이해인, <사랑하는 사람들이여>',
  '봄물보다 깊으니라\n' +
    '갈산보다 높으리라\n' +
    '달보다 빛나리라\n' +
    '돌보다 굳으리라\n' +
    '사랑을 묻는 이 있거든\n' +
    '이대로만 말하리\n' +
    '한용운, <사랑>',
  '별을 보고 있으면\n' +
    '어둠을 두려워할 필요가 없잖아요\n' +
    '별이 아름답구나, 그 생각부터 하게되니까\n' +
    '우리, 그렇게 사랑해요\n' +
    '정현주, <그래도, 사랑>',
  '멀리 있었으나 \n' +
    '서로의 빛을 바라볼 줄 알았고\n' +
    '어두웠으나 서로에게 다가갈 줄 알아\n' +
    '오늘 드디어 두 손을 잡는다\n' +
    '사랑은 소유가 아니라 \n' +
    '동행임을 아는 두 사람은\n' +
    '잡은 손을 놓지 않되 \n' +
    '함부로 잡아끌지 않을 것이며\n' +
    '서로의 두 눈을 고요히 바라보아 \n' +
    '말하지 않아도 같은 쪽을 걸어가리라\n' +
    '박미라, <아름다운 날에 부치다>',
];

export const letterNote1Options = letterNote1Values.map((note, index) => ({
  name: note,
  value: index.toString(),
}));

export const letterNote2Options = letterNote2Values.map((note, index) => ({
  name: note,
  value: index.toString(),
}));

export const letterAccountsTitleValues = ['마음을 담은 축의금', '마음 전하실 곳'];

export const letterAccountsDescriptionValues = [
  '신랑&신부에게 축의금을 먼저 전달하고\n싶으신가요?',
  '신랑&신부에게 축하의 마음을 전해주세요.',
];

export const letterAccountsTitleOptions = letterAccountsTitleValues.map((title, index) => ({
  name: title,
  value: index.toString(),
}));

export const letterAccountsDescriptionOptions = letterAccountsDescriptionValues.map(
  (title, index) => ({
    name: title,
    value: index.toString(),
  }),
);

export const letterAttendsTitleValues = ['참석의사 전달하기'];
export const letterAttendsDescriptionValues = ['신랑&신부에게 참석의사를\n미리 전달할 수 있어요.'];

export const letterAttendsTitleOptions = letterAttendsTitleValues.map((title, index) => ({
  name: title,
  value: index.toString(),
}));
export const letterAttendsDescriptionOptions = letterAttendsDescriptionValues.map(
  (description, index) => ({
    name: description,
    value: index.toString(),
  }),
);

export const defaultLetterGroomInfo: LetterGroomInfo = {
  name: '',
  showPhone: true,
  phone1: '',
  phone2: '',
  phone3: '',
  order: letterGroomInfoOrderOptions[0].value,
  fatherName: '',
  motherName: '',
  requireParentPhone: false,
  fatherPhone1: '',
  fatherPhone2: '',
  fatherPhone3: '',
  motherPhone1: '',
  motherPhone2: '',
  motherPhone3: '',
};

export const defaultLetterPriestInfo: LetterPriestInfo = {
  name: '',
  showPhone: true,
  phone1: '',
  phone2: '',
  phone3: '',
  order: letterPriestInfoOrderOptions[0].value,
  fatherName: '',
  motherName: '',
  requireParentPhone: false,
  fatherPhone1: '',
  fatherPhone2: '',
  fatherPhone3: '',
  motherPhone1: '',
  motherPhone2: '',
  motherPhone3: '',
};

export const defaultLetterPlaceInfo: LetterPlaceInfo = {
  date: '',
  time: '',
  zipCode: '',
  address: '',
  addressDetail: '',
  showPlace: true,
  phone1: '',
  phone2: '',
  phone3: '',
};

export const defaultLetterWayToComeInfo: LetterWayToComeInfo = {
  subway: '',
  bus: '',
  car: '',
  etc: '',
  mapImage: null,
};

export const defaultLetterPhotoInfo: LetterPhotoInfo = {
  mainImage: null,
  secondImage: null,
  lastImage: null,
  gallery: [],
  galleryType: 'swipe',
};

export const defaultLetterAccountsInfo: LetterAccountsInfo = {
  title: '',
  description: '',
  groom: null,
  priest: null,
  additionalGroom: null,
  additionalPriest: null,
};

export const defaultLetterThemeInfo: LetterThemeInfo = {
  theme: letterThemes[0].id,
};

export const defaultLetterOGInfo: LetterOGInfo = {
  title: LetterOGTitleVariant['김신랑, 박신부의 결혼을 축하해주세요.'],
};

export const defaultLetterAttendsInfo: LetterAttendsInfo = {
  enabled: false,
  title: '',
  description: '',
};

export const defaultLetterGuestBooksInfo: LetterGuestBooksInfo = {
  enabled: false,
};

const validatePhone = (
  phone1: Nullable<string>,
  phone2: Nullable<string>,
  phone3: Nullable<string>,
) => isNotEmpty(phone1) && isNotEmpty(phone2) && isNotEmpty(phone3);

export const validateGroomInfo = ({
  name,
  phone1,
  phone2,
  phone3,
  order,
  requireParentPhone,
  fatherPhone1,
  fatherPhone2,
  fatherPhone3,
  motherPhone1,
  motherPhone2,
  motherPhone3,
}: LetterGroomInfo) =>
  isNotEmpty(name) &&
  validatePhone(phone1, phone2, phone3) &&
  isNotEmpty(order) &&
  (requireParentPhone
    ? validatePhone(fatherPhone1, fatherPhone2, fatherPhone3) ||
      validatePhone(motherPhone1, motherPhone2, motherPhone3)
    : true);

export const validatePriestInfo = ({
  name,
  phone1,
  phone2,
  phone3,
  order,
  requireParentPhone,
  fatherPhone1,
  fatherPhone2,
  fatherPhone3,
  motherPhone1,
  motherPhone2,
  motherPhone3,
}: LetterPriestInfo) =>
  isNotEmpty(name) &&
  validatePhone(phone1, phone2, phone3) &&
  isNotEmpty(order) &&
  (requireParentPhone
    ? validatePhone(fatherPhone1, fatherPhone2, fatherPhone3) ||
      validatePhone(motherPhone1, motherPhone2, motherPhone3)
    : true);

export const validatePlaceInfo = ({ date, time, address, showPlace }: LetterPlaceInfo) =>
  isNotEmpty(date) &&
  isNotEmpty(time) &&
  // 결혼식 장소를 표기하지 않는 경우 주소입력 하지 않아도 됨
  (showPlace === false ? true : isNotEmpty(address));

export const validateAccountInfo = (info: LetterAccount | null | undefined) => {
  if (info == null) {
    return true;
  }

  return (
    (isNotEmpty(info.bankName) && isNotEmpty(info.no) && isNotEmpty(info.name)) ||
    isNotEmpty(info.kakaopayQRCodeUrl)
  );
};

export const validateAccountsInfo = ({ groom, priest }: LetterAccountsInfo) =>
  validateAccountInfo(groom) || validateAccountInfo(priest);

export const validateThemeInfo = ({ theme }: LetterThemeInfo) => isLetterTheme(theme);

export const validateOGInfo = ({ title }: LetterOGInfo) => isLetterOGTitleVariant(title);

export const validateAttendsInfo = ({ enabled, title, description }: LetterAttendsInfo) =>
  enabled ? isNotEmpty(title) && isNotEmpty(description) : true;

function isInRange<T>(val: string, arr: T[]) {
  if (val.trim().length === 0) {
    return false;
  }

  const index = Number(val);
  if (Number.isNaN(index)) {
    return false;
  }

  return 0 <= index && index < arr.length;
}

export function isLetterNote1Custom(note1: string) {
  return !isInRange(note1, letterNote1Values);
}

export function isLetterNote2Custom(note2: string) {
  return !isInRange(note2, letterNote2Values);
}

export function isLetterAccountsTitleCustom(title: string) {
  return !isInRange(title, letterAccountsTitleValues);
}

export function isLetterAccountsDescriptionCustom(description: string) {
  return !isInRange(description, letterAccountsDescriptionValues);
}

export function isLetterAttendsTitleCustom(title: string) {
  return !isInRange(title, letterAttendsTitleValues);
}

export function isLetterAttendsDescriptionCustom(description: string) {
  return !isInRange(description, letterAccountsDescriptionValues);
}

export const validateNoteInfo = ({ note1, note2 }: LetterNoteInfo) =>
  isNotEmpty(note1) && isNotEmpty(note2);

export const validatePhotoInfo = ({ mainImage, secondImage, lastImage }: LetterPhotoInfo) =>
  mainImage != null && secondImage != null && lastImage != null;

export const letterStorageKeys = {
  email: '@wm/email',
  groom: {
    name: '@wm/groom/name',
    showPhone: '@wm/groom/showPhone',
    phone1: '@wm/groom/phone1',
    phone2: '@wm/groom/phone2',
    phone3: '@wm/groom/phone3',
    order: '@wm/groom/order',
    fatherName: '@wm/groom/fatherName',
    motherName: '@wm/groom/motherName',
    requireParentPhone: '@wm/groom/requireParentPhone',
    fatherPhone1: '@wm/groom/fatherPhone1',
    fatherPhone2: '@wm/groom/fatherPhone2',
    fatherPhone3: '@wm/groom/fatherPhone3',
    motherPhone1: '@wm/groom/motherPhone1',
    motherPhone2: '@wm/groom/motherPhone2',
    motherPhone3: '@wm/groom/motherPhone3',
  },
  priest: {
    name: '@wm/priest/name',
    showPhone: '@wm/priest/showPhone',
    phone1: '@wm/priest/phone1',
    phone2: '@wm/priest/phone2',
    phone3: '@wm/priest/phone3',
    order: '@wm/priest/order',
    fatherName: '@wm/priest/fatherName',
    motherName: '@wm/priest/motherName',
    requireParentPhone: '@wm/priest/requireParentPhone',
    fatherPhone1: '@wm/priest/fatherPhone1',
    fatherPhone2: '@wm/priest/fatherPhone2',
    fatherPhone3: '@wm/priest/fatherPhone3',
    motherPhone1: '@wm/priest/motherPhone1',
    motherPhone2: '@wm/priest/motherPhone2',
    motherPhone3: '@wm/priest/motherPhone3',
  },
  place: {
    date: '@wm/place/date',
    time: '@wm/place/time',
    zipCode: '@wm/place/zipCode',
    address: '@wm/place/address',
    jibunAddress: '@wm/place/jibunAddress',
    addressDetail: '@wm/place/addressDetail',
    x: '@wm/place/x',
    y: '@wm/place/y',
    showPlace: '@wm/place/showPlace',
    phone1: '@wm/place/phone1',
    phone2: '@wm/place/phone2',
    phone3: '@wm/place/phone3',
  },
  wayToCome: '@wm/wayToCome',
  note: {
    note1: '@wm/note/note1',
    note2: '@wm/note/note2',
  },
  photo: {
    main: '@wm/photo/main',
    second: '@wm/photo/second',
    last: '@wm/photo/last',
    gallery: '@wm/photo/gallery',
    galleryType: '@wm/photo/galleryType',
  },
  accounts: '@wm/accounts',
  theme: {
    theme: '@wm/theme/theme',
  },
  og: {
    title: '@wm/og/title',
  },
  attends: '@wm/attends',
  guestBooks: '@wm/guestBooks',
  youtubeLink: '@wm/youtubeLink',
  youtubeLiveLink: '@wm/youtubeLiveLink',
} as const;

export interface LetterData {
  groom: {
    name: string;
    phone1: string;
    phone2: string;
    phone3: string;
    order: string;
    showPhone?: boolean;
    fatherName: string;
    motherName: string;
    fatherPhone1?: string;
    fatherPhone2?: string;
    fatherPhone3?: string;
    motherPhone1?: string;
    motherPhone2?: string;
    motherPhone3?: string;
    requireParentPhone?: boolean;
  };
  priest: {
    name: string;
    phone1: string;
    phone2: string;
    phone3: string;
    order: string;
    showPhone?: boolean;
    fatherName: string;
    motherName: string;
    fatherPhone1?: string;
    fatherPhone2?: string;
    fatherPhone3?: string;
    motherPhone1?: string;
    motherPhone2?: string;
    motherPhone3?: string;
    requireParentPhone?: boolean;
  };
  place: {
    date: string;
    time: string;
    zipCode: string;
    address: string;
    addressDetail: string;
    jibunAddress?: string;
    x?: string;
    y?: string;
    showPlace?: boolean;
    phone1: string;
    phone2: string;
    phone3: string;
  };
  wayToCome?: {
    subway?: string;
    bus?: string;
    car?: string;
    etc?: string;
    mapImage?: string | null;
  };
  note: {
    note1: string;
    note2: string;
  };
  accounts: LetterAccountsInfo;
  photo: {
    mainImage: string;
    secondImage: string;
    lastImage: string;
    gallery: string[];
    galleryType: LetterPhotoGalleryType;
  };
  og: {
    title: string;
  };
  attends?: LetterAttendsInfo;
  guestBooks?: LetterGuestBooksInfo;
  youtubeLiveLink?: string;
  youtubeLink?: string;
}

export interface LetterDataFormValues extends Omit<LetterData, 'photo'> {
  photo: {
    mainImage: string;
    secondImage: string;
    lastImage: string;
    gallery: Array<{ url: string }>;
    galleryType: LetterPhotoGalleryType;
  };
}

export function parseLetterDataToFormValues(letterData: LetterData): LetterDataFormValues {
  return {
    ...letterData,
    photo: {
      ...letterData.photo,
      gallery: letterData.photo.gallery?.map((url) => ({ url })) ?? [],
    },
  };
}

export function serializeLetterDataFormValuesToData(letterData: LetterDataFormValues): LetterData {
  return {
    ...letterData,
    photo: {
      ...letterData.photo,
      gallery: letterData.photo.gallery?.map(({ url }) => url) ?? [],
    },
  };
}

export interface LetterShareInfo {
  orderId: string;
  url: string;
  imageUrl: string;
  title: string;
  description: string;
  showPlace: boolean;
  address: string;
  addressDetail?: string;
  createdAt: string;
}

export enum LetterStatus {
  대기 = 'pending',
  서비스중 = 'in-service',
  취소요청 = 'cancel-requested',
  환불완료 = 'cancelled',
  만료 = 'expired',
}

const letterStatusOptions = objectToOptions(LetterStatus);

export function getLetterStatusName(status: LetterStatus) {
  if (status === LetterStatus.서비스중) {
    return '생성완료';
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return letterStatusOptions.find((x) => x.value === status)!.name;
}

export interface LetterAttend {
  id: number;
  letterId: number;
  attenderName: string;
  attenderKind: string;
  additionalAttenderCount: number;
  createdAt: string;
  updatedAt: string;
}

export interface LetterGuestBook {
  id: number;
  letterId: number;
  guestName: string;
  body: string;
  createdAt: string;
  updatedAt: string;
}

export interface LetterGuestBookWithLetterOrderId extends LetterGuestBook {
  letterOrderId: string;
}

export interface Letter {
  id: number;
  orderId: string;
  buildId: string;
  url: string;
  status: LetterStatus;
  theme: LetterTheme;
  enableAttends?: boolean;
  enableGuestBooks?: boolean;
  built: boolean | null;
  expired: boolean | null;
  /** yyyy-MM-dd */
  expiredAt: string;
  createdAt: string;
  updatedAt: string;
  payment: Payment;
  attends?: LetterAttend[];
  guestBooks?: LetterGuestBook[];
  shareInfo: Omit<LetterShareInfo, 'orderId' | 'url' | 'createdAt'>;
}

export interface LetterWithData extends Letter {
  data: string;
}
