import type { AnyObject, StringKeyOf, ValueOf } from './types';

export function enumKeys<T extends AnyObject>(value: T) {
  /**
   * NOTE: number enum의 경우 number 값도 프로퍼티에 있기 때문에 걸러줍니다.
   */
  return Object.keys(value).filter((x) => Number.isNaN(Number(x))) as Array<StringKeyOf<T>>;
}

export function enumValues<T extends AnyObject>(value: T) {
  return enumKeys(value).map((key) => value[key]);
}

export function enumEntries<T extends AnyObject>(value: T) {
  return enumKeys(value).reduce((entries, key) => {
    entries[key] = value[key];
    return entries;
  }, {} as Record<StringKeyOf<T>, T[StringKeyOf<T>]>);
}

export function getEnumKeyByValue<T extends AnyObject>(obj: T, value: T[StringKeyOf<T>]) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return enumKeys(obj).find((key) => obj[key] === value)!;
}

export function isEnumValue<T extends AnyObject>(enumLike: T, val: unknown): val is ValueOf<T> {
  return enumValues(enumLike).some((x) => x === val);
}
