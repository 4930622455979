import { AnyObject, enumKeys, StringKeyOf } from '@florencecard-lib/type-utils';

export interface Option<T extends string = string> {
  name: string;
  value: T;
}

export function objectToOptions<T extends AnyObject>(obj: T): Array<Option<T[StringKeyOf<T>]>> {
  return enumKeys(obj).map((key) => ({
    name: key,
    value: obj[key],
  }));
}
