export type LetterTheme =
  | 'theme1'
  | 'theme2'
  | 'theme3'
  | 'theme4'
  | 'wine1'
  | 'magazine1'
  | 'purple1'
  | 'yellowflower1'
  | 'whiteflower1'
  | 'flat1'
  | 'frame1'
  | 'manuscript1'
  | 'cursive1';

export const letterThemes: LetterTheme[] = [
  'theme1',
  'theme2',
  'theme3',
  'theme4',
  'wine1',
  'magazine1',
  'purple1',
  'yellowflower1',
  'whiteflower1',
  'flat1',
  'frame1',
  'manuscript1',
  'cursive1',
];

interface ImageSrcSets {
  '1x': string;
  '2x': string;
  '3x': string;
  src: string;
  srcset: string;
}

export interface LetterThemeMetadata {
  name: string;
  description: string;
  previewImage: ImageSrcSets;
  storeImage: ImageSrcSets;
  previewLink: string;
}

const getImageSrcSets = (baseUrl: string, extension = '.png'): ImageSrcSets => {
  const set = {
    '1x': `${baseUrl}${extension}`,
    '2x': `${baseUrl}@2x${extension}`,
    '3x': `${baseUrl}@3x${extension}`,
  };

  return {
    ...set,
    src: set['2x'],
    srcset: `${set['1x']} 1x, ${set['2x']} 2x, ${set['3x']} 3x`,
  };
};

export function getLetterThemePreviewImageSrcSets(theme: LetterTheme) {
  return getImageSrcSets(`https://assets.florencecard.me/images/theme-thumbnails/${theme}`, '.png');
}

export function getLetterThemeStoreImageSrcSets(theme: LetterTheme) {
  return getImageSrcSets(`https://assets.florencecard.me/images/store-thumbnails/${theme}`, '.png');
}

export const letterThemeMetadataMap: Record<LetterTheme, LetterThemeMetadata> = {
  theme1: {
    name: '블루',
    description: '고급스러운 다크블루 테마에요.',
    previewImage: getLetterThemePreviewImageSrcSets('theme1'),
    storeImage: getLetterThemeStoreImageSrcSets('theme1'),
    previewLink: 'https://letters.florencecard.me/samples/theme1',
  },
  theme2: {
    name: '그린',
    description: '산뜻한 느낌의 수채화 테마에요.',
    previewImage: getLetterThemePreviewImageSrcSets('theme2'),
    storeImage: getLetterThemeStoreImageSrcSets('theme2'),
    previewLink: 'https://letters.florencecard.me/samples/theme2',
  },
  theme3: {
    name: '플라워',
    description: '꽃다발 가득한 향기로운 테마에요.',
    previewImage: getLetterThemePreviewImageSrcSets('theme3'),
    storeImage: getLetterThemeStoreImageSrcSets('theme3'),
    previewLink: 'https://letters.florencecard.me/samples/theme3',
  },
  theme4: {
    name: '그레이',
    description: '흑백사진이 어울리는 아날로그 테마에요.',
    previewImage: getLetterThemePreviewImageSrcSets('theme4'),
    storeImage: getLetterThemeStoreImageSrcSets('theme4'),
    previewLink: 'https://letters.florencecard.me/samples/theme4',
  },
  wine1: {
    name: '와인',
    description: '정열적인 와인컬러를 담은 테마에요.',
    previewImage: getLetterThemePreviewImageSrcSets('wine1'),
    storeImage: getLetterThemeStoreImageSrcSets('wine1'),
    previewLink: 'https://letters.florencecard.me/samples/wine1',
  },
  magazine1: {
    name: '매거진',
    description: '매거진 느낌의 깔끔한 테마에요.',
    previewImage: getLetterThemePreviewImageSrcSets('magazine1'),
    storeImage: getLetterThemeStoreImageSrcSets('magazine1'),
    previewLink: 'https://letters.florencecard.me/samples/magazine1',
  },
  purple1: {
    name: '퍼플',
    description: '고급스러운 필기체가 눈에 띄는 테마에요.',
    previewImage: getLetterThemePreviewImageSrcSets('purple1'),
    storeImage: getLetterThemeStoreImageSrcSets('purple1'),
    previewLink: 'https://letters.florencecard.me/samples/purple1',
  },
  yellowflower1: {
    name: '옐로우플라워',
    description: '옐로우 컬러가 돋보이는 깔끔한 테마에요.',
    previewImage: getLetterThemePreviewImageSrcSets('yellowflower1'),
    storeImage: getLetterThemeStoreImageSrcSets('yellowflower1'),
    previewLink: 'https://letters.florencecard.me/samples/yellowflower1',
  },
  whiteflower1: {
    name: '화이트플라워',
    description: '하얀 꽃으로 깔끔하게 장식한 테마에요.',
    previewImage: getLetterThemePreviewImageSrcSets('whiteflower1'),
    storeImage: getLetterThemeStoreImageSrcSets('whiteflower1'),
    previewLink: 'https://letters.florencecard.me/samples/whiteflower1',
  },
  flat1: {
    name: '플랫',
    description: '심플하고 플랫한 기본 테마에요.',
    previewImage: getLetterThemePreviewImageSrcSets('flat1'),
    storeImage: getLetterThemeStoreImageSrcSets('flat1'),
    previewLink: 'https://letters.florencecard.me/samples/flat1',
  },
  frame1: {
    name: '프레임',
    description: '사진첩 감성의 트렌디한 테마에요.',
    previewImage: getLetterThemePreviewImageSrcSets('frame1'),
    storeImage: getLetterThemeStoreImageSrcSets('frame1'),
    previewLink: 'https://letters.florencecard.me/samples/frame1',
  },
  manuscript1: {
    name: '원고지',
    description: '원고지에 꽃갈피가 놓여진 테마에요.',
    previewImage: getLetterThemePreviewImageSrcSets('manuscript1'),
    storeImage: getLetterThemeStoreImageSrcSets('manuscript1'),
    previewLink: 'https://letters.florencecard.me/samples/manuscript1',
  },
  cursive1: {
    name: '필기체',
    description: '필기체로 장식된 심플한 테마에요.',
    previewImage: getLetterThemePreviewImageSrcSets('cursive1'),
    storeImage: getLetterThemeStoreImageSrcSets('cursive1'),
    previewLink: 'https://letters.florencecard.me/samples/cursive1',
  },
};
